@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,500&family=Zen+Kaku+Gothic+New:wght@400;500;700&display=swap');

body {
  color: #333;
}
a {
  color: #333;
}

.main-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: url('./img/bg_logo.png'), linear-gradient(180deg, rgba(61, 200, 194, 0.3), rgba(206, 216, 0, 0.3) 30%, rgba(255, 174, 68, 0.3) 74%, rgba(234, 114, 0, 0.3)), #fff;
  background-size: min(400px,100%) auto, 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.footer-logo {
  width: 200px;
  margin: auto;
}

.login-chara {
  width: 150px;
  margin-bottom: 8px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* parts
========================== */
/* chipRibbon */
.chipRibbon {
  display: inline-block;
  position: absolute;
  top: -6px;
  right: 10px;
  margin: 0;
  padding: 12px 0 4px;
  z-index: 2;
  width: 70px;
  text-align: center;
  color: white;
  font-size: 13px;
  background: #fa8383;
  border-radius: 2px 0 0 0;
  font-feature-settings: 'palt';
  letter-spacing: 0.01em;
  font-weight: 700;
}
.chipRibbon:before {
  position: absolute;
  content: '';
  top: 0;
  right: -5px;
  border: none;
  border-bottom: solid 6px #d07676;
  border-right: solid 5px transparent;
}
.chipRibbon:after {
  content: '';
  position: absolute;
  left: 0;
  top: 99%;
  height: 0;
  width: 0;
  border-left: 35px solid transparent;
  border-right: 35px solid transparent;
  border-top: 8px solid #fa8383;
}
